import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Hakkımızda | Justitia Hukuk Danışmanları Hakkında
			</title>
			<meta name={"description"} content={"Justitia Hukuk Danışmanları, geleneksel hukuk uzmanlığını yenilikçi yaklaşımlarla harmanlayarak hukuk hizmetlerinde yeni bir standart oluşturma vizyonuyla yola çıktı. "} />
			<meta property={"og:title"} content={"Hakkımızda | Justitia Hukuk Danışmanları Hakkında"} />
			<meta property={"og:description"} content={"Justitia Hukuk Danışmanları, geleneksel hukuk uzmanlığını yenilikçi yaklaşımlarla harmanlayarak hukuk hizmetlerinde yeni bir standart oluşturma vizyonuyla yola çıktı. "} />
			<meta property={"og:image"} content={"https://erimides.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://erimides.com/img/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://erimides.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://erimides.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://erimides.com/img/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://erimides.com/img/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://erimides.com/img/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Hakkımızda
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Justitia Hukuk Danışmanları, geleneksel hukuk uzmanlığını yenilikçi yaklaşımlarla harmanlayarak hukuk hizmetlerinde yeni bir standart oluşturma vizyonuyla yola çıktı. Firmamız dürüstlük, uzmanlık ve müşteri odaklı sonuçlar temelleri üzerine inşa edilmiştir. Kendini işine adamış avukatlardan oluşan küçük bir ekipten, her biri hukuk alanına benzersiz güçlerini getiren çeşitli bir gruba dönüştük.
				</Text>
				<Link
					href="/contact"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-primary"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Bize Ulaşın
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://erimides.com/img/3.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" md-padding="60px 0 60px 0" background="linear-gradient(180deg,rgba(42, 9, 113, 0.7) 0%,rgba(12, 36, 127, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://erimides.com/img/4.jpg) 50% 0/cover repeat scroll padding-box" quarkly-title="Reviews-8">
			<Override slot="SectionContent" md-padding="0px 0 0px 0" />
			<Box
				min-width="100px"
				min-height="100px"
				padding="30px 0 10px 0"
				border-radius="8px"
				margin="0px 20px 25px 20px"
				display="flex"
				flex-direction="row"
				justify-content="space-between"
				lg-margin="0px 0 25px 0px"
				md-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 0px 0px 50px"
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-padding="30px 0px 0px 0"
				>
					<Box min-width="100px" min-height="100px">
						<Text
							margin="0px 0px 16px 0px"
							color="--light"
							font="--headline3"
							lg-text-align="center"
							sm-text-align="left"
						>
							Bizim Felsefemiz
						</Text>
						<Text margin="0px 0px 30px 0px" font="normal 400 22px/1.5 --fontFamily-sans" color="#e8e3e3" sm-margin="0px 0px 20px 0px">
							Justitia'nın temelinde adalet ve mükemmelliğe olan bağlılık yatmaktadır. Müvekkillerimizi bilinçli kararlar ve açık, dürüst tavsiyeler yoluyla güçlendirmeye inanıyoruz. Yaklaşımımız her zaman her vakanın özel ihtiyaçlarına ve koşullarına göre uyarlanır ve profesyonel bir ortamda kişisel bir dokunuş sağlar.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="48px 0 48px 0" sm-padding="60px 0 60px 0" quarkly-title="About-14">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="flex"
				width="40%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://erimides.com/img/4.jpg"
					object-fit="cover"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					sm-min-height="100vw"
					height="100%"
				/>
			</Box>
			<Box
				display="flex"
				width="60%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="48px 64px 48px 64px"
				justify-content="center"
				background="white"
				md-padding="36px 40px 48px 40px"
				sm-padding="36px 24px 48px 24px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Bizim Takımımız
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					sm-text-align="left"
				>
					Ekibimiz, hukuk konusunda tutkulu ve müvekkillerinin başarısına kendini adamış bireylerden oluşmaktadır. Dinamik bir yasal ortamda bir adım önde olmak için bilgi ve becerilerimizi sürekli güncelleyerek müvekkillerimiz için mümkün olan en iyi hizmeti sağlıyoruz.
				</Text>
				<Text
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Taahhüdümüz
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					lg-margin="0px 0px 36px 0px"
					sm-text-align="left"
				>
					Bizler hukuki danışmanlardan daha fazlasıyız - haklarınızın ve menfaatlerinizin savunucusuyuz. Taahhüdümüz mahkeme salonunun veya müzakere masasının ötesine uzanır. Müvekkillerimizle güven, saygı ve karşılıklı anlayışa dayalı kalıcı ilişkiler kurmayı hedefliyoruz.
				</Text>
				<Link
					href="/contact"
					color="--primary"
					font="--lead"
					text-decoration-line="initial"
					hover-text-decoration-line="underline"
				>
					Bizi Ziyaret Edin
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});